import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import Page from '../components/Page';
import { Footer, MainNavigation } from '../components/StaticPageComponents';
import Typography from '../components/Typography';

const Home = () => {
  return (
    <Page title="Home" id="page-home">
      <MainNavigation />
      <Container>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: '80vh' }}
        >
          <Grid item xs={12} sm={10} md={8}>
            <Box textAlign="center">
              <Typography isLegacy variant="h2" color="textSecondary">
                {'Nothing to see here.'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </Page>
  );
};

export default Home;
